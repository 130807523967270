@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=Baloo+Da+2:wght@400..800&family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .bg-transparent-pale-aqua {
    @apply bg-gradient-to-b from-[rgba(212,242,231,0.4)] to-[rgba(212,242,231,0.4)];
    backdrop-filter: blur(18px);
  }

  .border-transparent-yellow {
    border-color: rgba(213, 225, 255, 0.3);
  }

  .bg-transparent-light-blue {
    @apply bg-gradient-to-b from-[rgba(167,180,222,0.5)] to-[rgba(167,180,222,0.2)];
    backdrop-filter: blur(8px);
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
}